import React, {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import MinimalLayout from './../layout/MinimalLayout';

import RequireAuth from './RequireAuth';
// login option 3
// const AuthLogin3 = lazy(() => import('../views/pages/authentication/Login'));
// const AuthRegister3 = lazy(() => import('../views/pages/authentication/Register'));

const LoginPage = lazy(() => import('../views/authentication/Login'));
const ChangeDepo = lazy(() => import('../views/depo/ChangeDepo'));

const AuthenticationRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/',
                '/change'
            ]}
        >
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <Route path="/" exact component={LoginPage} />
                    <Route path="/change" component={RequireAuth(ChangeDepo)} />
                    {/* <Route path="/register" component={AuthRegister3} /> */}
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default AuthenticationRoutes;
