import React, {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';

import MainLayout from './../layout/MainLayout';

import RequireAuth from './RequireAuth';

const DashboardDefault = lazy(() => import('../views/dashboard/Default'));
const ProductList = lazy(() => import('../views/products/ProductList'));
const SalesList = lazy(() => import('../views/sales/SalesList'));
const StokTransfer = lazy(() => import('../views/stok/StokTransfer'));
const SalesDetail = lazy(() => import('../views/sales/SalesDetail'));
const TransactionList = lazy(() => import('../views/transaction/TransactionList'));
const TransactionDetail = lazy(() => import('../views/transaction/TransactionDetail'));
const MasterOutlet = lazy(() => import('../views/outlet/MasterOutletList'));

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard',
                '/product/list',
                '/sales/list',
                '/sales/stok',
                '/sales/:id',
                '/transaction/list',
                '/transaction/:id',
                '/outlet',
            ]}
        >
            <MainLayout showBreadcrumb={true}>
                <Switch location={location} key={location.pathname}>
                    <Route path="/dashboard" component={RequireAuth(DashboardDefault)} />
                    <Route path="/product/list" component={RequireAuth(ProductList)} />
                    <Route path="/sales/list" component={RequireAuth(SalesList)} />
                    <Route path="/sales/stok" component={RequireAuth(StokTransfer)} />
                    <Route path="/sales/:id" component={RequireAuth(SalesDetail)} />
                    <Route path="/transaction/list" component={RequireAuth(TransactionList)} />
                    <Route path="/transaction/:id" component={RequireAuth(TransactionDetail)} />
                    <Route path="/outlet" component={RequireAuth(MasterOutlet)} />
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
