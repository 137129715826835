import { createStore, applyMiddleware, compose } from 'redux';

import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from '../reducers/rootReducer';

const store = createStore(rootReducer, compose(
  applyMiddleware(thunkMiddleware)
    // applyMiddleware(thunkMiddleware, logger)
));
export default store;
