import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

export default function RequireAuth (ComposedComponent) {
    class Authenticate extends Component {
        // static contextTypes = {
        //     router: PropTypes.object.isRequired
        // };
        static propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
        };
        UNSAFE_componentWillMount() {
            if (!this.props.isAuthenticated) {
                this.props.history.push('/');
            }
        }
        UNSAFE_componentWillUpdate(nextProps) {
            if (!nextProps.isAuthenticated) {
                this.props.history.push('/dashboard');
            }
        }
        render() {
            return <ComposedComponent {...this.props} />;
        }
    }
    function mapStateToProps(state) {
        // console.log(state.auth.isAuthenticated)
        return {
            isAuthenticated: state.auth.isAuthenticated
        };
    }

    return connect(mapStateToProps)(Authenticate);
}
