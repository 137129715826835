const commons = {
    PRODUCT: 'product',
    STOK_DEPO: 'productstok',
    POST_STOK_DEPO: 'poststokdepo',
    ADD_STOK_DEPO: 'addstokdepo',
    GET_KOWIL: 'kowil',
    SALES_LIST: 'saleslist',
    POST_SALES: 'postsales',
    POST_STOK_SALES: 'poststoksales',
    STOK_HISTORY: 'stokhistory',
    SALES_STOK: 'salesstok',
    TOTAL_TRANSACTION: 'totaltransaction',
    SALES_TOTAL_TRANSACTION: 'salestotaltransaction',
    TOTAL_ORDER: 'totalorder',
    SALES_TRANSACTION: 'salestransaction',
    SALES_PROFILE: 'salesprofile',
    TRANSACTION_DETAIL: 'transactiondetail',
    LIST_DEPO: 'listdepo',
    POST_DEPO: 'postdepo',
    LIST_TRANSACTION: 'transactionlist',
    ADD_STOK_SALES: 'addstoksales',
    POST_CONFIRM_REPORT: 'postconfirmreport',
    PROFILE_ME: 'profileme',
    LIST_USER: 'listuser',
    CHANGE_SALES: 'changesales',
    MASTER_OUTLET: 'masteroutlet',
    TEMPLATE_PRODUCT: 'templateproduct',
    UPLOAD_TEMPLATE_PRODUCT: 'uploadtemplateproduct',
};

export default commons
