import axios from 'axios';
import Cookies from 'universal-cookie';
import AppConstant from '../constants/app';

const cookies = new Cookies();

let headers = {
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${cookies.get(AppConstant.TOKEN)}`
    }
}

export function fetch(url, pathParam, data) {
    return axios
        .get(url + pathParam + data, headers);
}

export function store(url, pathParam, data) {
    return axios
        .post(url + pathParam, data, headers);
}

export function update(url, pathParam, data) {
    return axios
        .put(url + pathParam, data, headers);
}

export function destroy(url, pathParam) {
    return axios
        .delete(url + pathParam, headers);
}

export async function storeWithUpload(url, pathParam, data) {
    const token = await cookies.get(AppConstant.TOKEN);
    headers = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
        }
    }
    return axios.post(url + pathParam, data, headers);
}