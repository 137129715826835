import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

// import {createStore} from 'redux';
import {Provider} from 'react-redux';

import App from './App';
import store from './redux/store';
import config from './config';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';

// const store = createStore(rootReducer);
import { verifyToken } from './redux/services/authService';

// Used to log user in if token is valid
store.dispatch(verifyToken());

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
